import { MasterLayout } from 'layout/master/main';
import { AppController } from 'app/controller';
import { app } from 'app/app';
import './styles.scss';

class ErrorController extends AppController {
    subs() {
        this.layout = new MasterLayout(document.documentElement);
    }
}

app.boot(ErrorController);

if (module.hot) {
    module.hot.accept(module.children, () => {
        // eslint-disable-next-line no-console
        console.log('Rebooting controller due to HMR...');
        app.boot(ErrorController);
    });

    module.hot.accept(() => {});
}
